import * as React from 'react';
import {Provider} from 'react-globally';

import {FeatureAppDefinition} from '@feature-hub/core';
import {ReactFeatureApp} from '@feature-hub/react';
import {ThemeProvider} from '@volkswagen-onehub/components-core';
import App from './components/App';
import i18next from 'i18next';

var getUrl = window.location;
var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

let path = "";
let isLive = false;
if (baseUrl.includes("localhost")){
  path = "https://prototype.de.ddb.com/fsag-calculator/img/";
}
else if (baseUrl.includes("lighthouselabs")){
  path = "https://prototype.de.ddb.com/fsag-calculator/img/";
}
else if (baseUrl.includes("volkswagen")){
  path = "https://finanzierungsrechner.vwfs.de/img/";
  isLive = true;
}
if (!isLive) {
  console.log("isLive: " + isLive);
  console.log("use img path: " + path);
  
  console.log("getUrl: " + getUrl);
  console.log("baseUrl: " + baseUrl);
}

let listBase = 'vehicle_list';
let requestDomain = "VW.NEW";
let requestDomainElectric = "VW.ID.DE";

const initialState = {
  baseUrl: baseUrl,
  imagePath: path,
  isLive: isLive,
  listBase: listBase,
  requestDomain: requestDomain,
  requestDomainElectric: requestDomainElectric,
  flktySelectedIndex: 0,
  tabSelectedIndex: 0,
  prevTabSelectedIndex: 0,
  lastChanged: "",
  vergleichen: false,
  kunden: false,

  value: 36,
  value2: 15000,
  leasingSonderZahlung: 1000,
  loadingLeasing: true,
  dataLeasing: {},

  value3: 24, // 06.10.2020, inaktiv, läuft über 'value' https://jira.de.ddb.com/projects/FRF/issues/FRF-63
  value4: 15000, // 06.10.2020, inaktiv, läuft über 'value2' https://jira.de.ddb.com/projects/FRF/issues/FRF-63
  ACSonderZahlung: 5000, // inaktiv, läuft über 'leasingSonderZahlung' https://jira.de.ddb.com/projects/FRF/issues/FRF-63
  loadingAC: true,
  dataAC: {},

  value5: 24, // 06.10.2020, inaktiv, läuft über 'value' https://jira.de.ddb.com/projects/FRF/issues/FRF-63
  value6: 15000, // 06.10.2020, inaktiv, läuft über 'value2' https://jira.de.ddb.com/projects/FRF/issues/FRF-63
  CCSonderZahlung: 5000, // inaktiv, läuft über 'leasingSonderZahlung' https://jira.de.ddb.com/projects/FRF/issues/FRF-63
  loadingCC: true,
  dataCC: {},

  loadingLeaseCareS: true,
  dataPL_S: {},
  loadingLeaseCareM: true,
  dataPL_M: {},
  loadingLeaseCareL: true,
  dataPL_L: {},

  selectedModel: i18next.t(listBase)[0],
  selectedList: [0],

  showModal: false,
  showInfo: false,
  draggable: false,

  showCalcSpinner: false,

  calcErrored: false,

  err_test: false,

  trackingService: null
};

// const rootEl = document.getElementById('root');

// render(
//   <Provider globalState={initialState}>
//     <ThemeProvider>
//       <App />
//     </ThemeProvider>
//   </Provider>,
//   rootEl
// );
const findCarId = (item:any) => {
  const carList: any = i18next.t(listBase);
  for (let i = 0; i < carList.length; i ++) {
    if (carList[i].name === item) {
      return i;
    }
  }
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp> = {
  dependencies: {
    featureServices: {
      "fa-content-service": "^1.0.0",
      "tracking": "^1.0.0"
    }
  },
  optionalDependencies: {
    featureServices: {
      "tracking": "^1.0.0"
    }
  },
  create: (env) => {

    // Handle predefined Settings
    const faFeatureService: any = env.featureServices["fa-content-service"]

    if (faFeatureService && faFeatureService.content) {
      const preDefined = faFeatureService.content;

      // TBD: nfz + id in "usecase" vereinen!
      
      // if NFZ, load NFZ vehicle list // Nutzfahrzeuge
      if (preDefined.nfz === "nfz") {
        //console.log("featureService.content.nfr: " + preDefined.nfz + ", nfz_list triggered");
        listBase = "nfz_list"
        initialState.listBase = listBase;
        initialState.requestDomain = "VW.VWNFZ.ICC.PL";
        initialState.selectedModel = i18next.t(listBase)[0];
      }
      if (preDefined.nfz === "id") {
        //console.log("featureService.content.nfr: " + preDefined.nfz + ", electric_list triggered");
        listBase = "electric_list"
        initialState.listBase = listBase;
        initialState.requestDomain = "VW.NEW";
        initialState.selectedModel = i18next.t(listBase)[0];
      }

      // set preselected Tab
      if (preDefined.Solution === "Leasing") {
        initialState.tabSelectedIndex = 0;
      }
      else if (preDefined.Solution === "Finanzierung") {
        initialState.tabSelectedIndex = 1;
      }
      // get and replace duration time
      if (preDefined.Laufzeit) {
        initialState.value = preDefined.Laufzeit;
      }
      // get and replace Downpay amount
      if (preDefined.Anzahlung) {
        initialState.leasingSonderZahlung = preDefined.Anzahlung;
      }
      // get and replace Mileage
      if (preDefined.Laufleistung) {
        initialState.value2 = preDefined.Laufleistung
      }
      // get Car Model Name, find ID in List, set as selected Model
      if (preDefined.Carmodel) {
        const preSetCars = preDefined.Carmodel.split(', ');
        initialState.selectedList = []
        preSetCars.forEach(car => {
          let pos = findCarId(car)
          initialState.selectedList.push(pos)
        });
        initialState.selectedModel = i18next.t(listBase)[initialState.selectedList[0]]
      }
    }
    
    const trackingService: any = env.featureServices["tracking"]
    if (trackingService) {
      initialState.trackingService = trackingService
    }

    return {
      render: () => (
        <Provider globalState={initialState}>
          <ThemeProvider theme="main">
            <App />
          </ThemeProvider>
        </Provider>
      )
    };
  }
};

export default featureAppDefinition;
