
import * as React from "react"
import "../i18n"
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { hot } from "react-hot-loader"
import "../assets/scss/App.scss"
import { throttle } from 'lodash'
import "../assets/scss/Flickity.scss"
import Flickity from 'react-flickity-component'
import {  Info, ChevronLeft, ChevronRight } from '@volkswagen-onehub/icons-core'
import {
    Text,
    TextAppearance,
    TextColor,
    ContainerPadding,
    ContainerHorizontalAlignment,
    Container,
    ContainerWrap,
    ContainerGutter,
    ContainerVerticalAlignment,
    Breakpoints,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import styled from "styled-components"

const StyledText = styled.div`
      border-bottom: 1px solid ${props => props.theme.border.color.primary};     
    `;

const StyledDivCol1 = styled.div`
    padding-left: ${props => props.theme.size.static200};
    width: ${(props) => props.theme.size.grid012};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid007};
    }
    @media print {
        width: 250px;
    }
`
const StyledDivCol10 = styled.div`
    display: none;
    margin-right: ${props => props.theme.size.static450};
    @media (min-width: ${Breakpoints.b960}px) {
        display: block;
    }
`
const StyledDivCol11 = styled.div`
    width: ${(props) => props.theme.size.grid012};
    white-space: nowrap;
    @media (min-width: ${Breakpoints.b960}px) {
        opacity: 0;
        width: 0px;
    }
    @media print {
        display: none;
    }
`
const StyledDivCol12 = styled.div`
display: block;
    @media (min-width: ${Breakpoints.b960}px) {
        display: none;    
    }
    @media print {
        width: 180px;
        text-align: center;
    }
`

const StyledDivCol2 = styled.div`
    padding-right: ${props => props.theme.size.static200};
    width: ${(props) => props.theme.size.grid007};
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${(props) => props.theme.size.grid004};
    }
    @media print {
        width: 180px;
        text-align: center;
    }
`

const StyledDivCarousel  = styled.div`
    min-height: 100px;
    width: ${props => props.theme.size.grid020};
        
    @media (min-width: ${Breakpoints.b960}px) {
        width: ${props => props.theme.size.grid022};
    }
`

const StyledDivChevron  = styled.div`
   display: block;
   @media (min-width: ${Breakpoints.b960}px) {
    display: none;
    }
`

const StyledDivTitle = styled.div`
    padding-top: ${props => props.theme.size.static200};
    padding-bottom: ${props => props.theme.size.static350};
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    @media (min-width: ${Breakpoints.b960}px) {
        justify-content: flex-end;
    }
    @media print {
        width: 180px;
    }
`

const StyledDivTitleInner = styled.div`
    width:${props => props.theme.size.grid004};
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
`

const PrintButton = styled.button`
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
`

class Vergleichen extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            flkty: null
        }      
        this.resize = throttle(this.resize.bind(this), 100)
    }
  
    resize = () => {
        if (this.state.flkty) {
            if (window.innerWidth < 768) {
                this.state.flkty.isDraggable = true
            } else {
                this.state.flkty.isDraggable = false;
                this.state.flkty.select(0, 0, false, true);
            }
        }
    }
  
    componentDidMount() {
        this.resize()
        window.addEventListener('resize', this.resize)
        this.props.tracking("table", this.props.globalState.dataLeasing, "Vergleichstabelle")
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.globalState.selectedModel !== this.props.globalState.selectedModel) {
            if (!this.props.globalState.isLive) {
                console.log("car changed")
            }
            this.props.tracking("table", this.props.globalState.dataLeasing, "Vergleichstabelle")
        }
    }
  
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
   
    formatNumber(numberString) {
        // remove currency symbol
        numberString = numberString.replace(/€/g, "");
        // turn any decimal comma into a dot for further editing
        numberString = numberString.replace(/,/g, ".");
        // turn string into actual number
        let newNumber = parseFloat(numberString).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
        return newNumber;
    }
    
    render() {

        let leasing_net_sum = (this.props.globalState.listBase === "nfz_list") ? `${this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["NetCreditAmount"])} ${i18next.t("currency_label")}` : "-";
        let leasing_rate_offer_disclaimer = (this.props.globalState.listBase === "nfz_list") ? "nfz_leasing_rate_offer_disclaimer" : "leasing_rate_offer_disclaimer";
        return (
            <Container wrap={ContainerWrap.always} stretchContent padding={{top: ContainerPadding.dynamic0150, bottom: ContainerPadding.dynamic0250}}>

                <Container verticalAlign={ContainerVerticalAlignment.flexStart} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.center}>

                    <StyledDivChevron onClick={() => { this.state.flkty.previous() }}>
                        <ChevronLeft />
                    </StyledDivChevron>
  
                        <StyledDivCarousel>

                            <Flickity  className={'carousel vergleichen'} // default ''
                                        elementType={'div'} // default 'div'
                                        options={{
                                            initialIndex: this.props.globalState.flktySelectedIndex, 
                                            wrapAround: false, 
                                            prevNextButtons: false, 
                                            cellAlign: 'left', 
                                             
                                            adaptiveHeight: false,
                                            draggable: window.innerWidth < 768 ? true : false
                                        }} // takes flickity options {}
                                        disableImagesLoaded={false} // default false
                                        reloadOnUpdate // default false
                                        static // default false
                                        flickityRef={(c) => {
                                             
                                            this.setState({flkty: c});
                                            
                                            c.on('settle', () => {
                                                /*console.log(`settle index is ${c.selectedIndex}`)*/
                                            })
                                            c.on('staticClick', (e, p, c, i) => {
                                                /*console.log(`select index is ${i}`)*/
                                            })

                                            // c.on( 'scroll', ( e, p ) {
                                                
                                            // });

                                        }}>

                                        <div>
                                            <StyledDivTitle>
                                                <StyledDivTitleInner>
                                                    <Container gutter={ContainerGutter.static200} verticalAlign={ContainerVerticalAlignment.center} wrap={ContainerWrap.never}>
                                                        <Text appearance={TextAppearance.headline0200} bold={true}>{this.props.globalState.dataLeasing["Response"]["Result"][0]["Product"]["@Label"]}</Text>
                                                        <CTA
                                                            tag="button"
                                                            emphasis="tertiary"
                                                            onClick={() => { this.props.setGlobalState({showInfo:"leasing"})}}
                                                            ariaLabel="Info"
                                                            size="small"
                                                            icon={<Info />}
                                                        >
                                                        </CTA>
                                                    </Container>
                                                </StyledDivTitleInner>
                                            </StyledDivTitle>
                                            
                                            <Container wrap={ContainerWrap.always}>

                                                {/* Runtime */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("duration")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.props.globalState.value} <Trans i18nKey="months" /></Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                        
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Milage */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("annual_milage")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.props.formatNumber(this.props.globalState.value2)} {i18next.t('milage_short')}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Downpayment */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        <Text appearance={TextAppearance.copy0150}><Trans i18nKey="leasing_downpayment" />/<Trans i18nKey="financing_downpayment" /></Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["DownPayment"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Netto Sum */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        <Text appearance={TextAppearance.copy0150}><Trans i18nKey="net_credit_amount_label" />/<wbr /><Trans i18nKey="purchase_price" /></Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{leasing_net_sum}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Interest */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                                    <StyledDivCol1>
                                                        <Container wrap={ContainerWrap.always}>
                                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_nominal_label")}</Text>
                                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                                <div className="divider-custom-vw-blue"></div>
                                                            </Container>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container wrap={ContainerWrap.always}>
                                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                                <Text appearance={TextAppearance.copy0150}>{/*{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["InterestNominal"])} %*/}-</Text>
                                                            </Container>
                                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                                <div className="divider-custom-vw-blue"></div>
                                                            </Container>
                                                        </Container>
                                                    </StyledDivCol2>                                
                                                </Container>

                                                {/* Accrued Interest */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween}>
                                                    <StyledDivCol1>
                                                        <Container wrap={ContainerWrap.always}>
                                                            <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_label")}</Text>
                                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                                <div className="divider-custom-vw-blue"></div>
                                                            </Container>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container wrap={ContainerWrap.always}>
                                                            <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                                <Text appearance={TextAppearance.copy0150}>{/*{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["Interest"])} %*/}-</Text>
                                                            </Container>
                                                            <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                                <div className="divider-custom-vw-blue"></div>
                                                            </Container>
                                                        </Container>
                                                    </StyledDivCol2>                                
                                                </Container>

                                                {/* Brutto Sum */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("loan_amount")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Tracking"]["Financing"]["TotalSum"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Final Rate */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("final_rate_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>-</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Monthly Rate */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        <Text appearance={TextAppearance.copy0150} bold>{this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["RateCount"]} {i18next.t("monthly_rate_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static200}} stretchContent>
                                                        <div className="divider-custom-vw-blue-thick"></div>
                                                        </Container>
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataLeasing["Response"]["Result"][1]["Calculation"]["Rate"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static200}} stretchContent>
                                                            <div className="divider-custom-vw-blue-thick"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Rate Offer Disclaimer */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol1>
                                                        
                                                    </StyledDivCol1>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}><Trans i18nKey={leasing_rate_offer_disclaimer} /><br/>&nbsp;</Text>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                <Container stretchContent padding={{top: ContainerPadding.static450, left: ContainerPadding.static200,  right: ContainerPadding.static200}}  wrap={ContainerWrap.never} verticalAlign={ContainerVerticalAlignment.center}>
                                                    <Container verticalAlign={ContainerVerticalAlignment.center} padding={{top: ContainerPadding.static450, left: ContainerPadding.static200}}>
                                                        <StyledDivCol10>
                                                            <CTA
                                                                tag="button"
                                                                emphasis="primary"
                                                                onClick={() => { this.props.setGlobalState({vergleichen: false}); this.props.setGlobalState({tabSelectedIndex: this.props.globalState.prevTabSelectedIndex}) }}
                                                                ariaLabel={i18next.t('back')}
                                                            >
                                                                <Trans i18nKey="back" />
                                                            </CTA>
                                                        </StyledDivCol10>
                                                    </Container>
                                                    <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd} verticalAlign={ContainerVerticalAlignment.center}>
                                                        <StyledText>
                                                            <CTA
                                                                tag="a"
                                                                emphasis="tertiary"
                                                                size="small"
                                                                href={this.props.globalState.selectedModel["request_offer_link"]}
                                                                target={"_blank"}
                                                                ariaLabel={i18next.t('request_a_quote')}
                                                                onClick={() => { this.props.tracking("cta", this.props.globalState.dataLeasing, {url: `${this.props.globalState.selectedModel["request_offer_link"]}`, name: "Angebot Anfordern"}) }}
                                                            >
                                                                <Trans i18nKey="request_a_quote" />
                                                            </CTA>
                                                        </StyledText>
                                                    </Container>
                                                </Container>
                                                
                                            </Container>

                                        </div>
                                        <div>

                                            <StyledDivTitle>
                                                <StyledDivTitleInner>
                                                    <Container gutter={ContainerGutter.static200} verticalAlign={ContainerVerticalAlignment.center} wrap={ContainerWrap.never}>
                                                        <Text appearance={TextAppearance.headline0200} bold={true}><Trans i18nKey="auto_credit" /></Text>
                                                        <CTA
                                                            tag="button"
                                                            emphasis="tertiary"
                                                            onClick={() => { this.props.setGlobalState({showInfo:"auto_credit"})}}
                                                            ariaLabel="Info"
                                                            size="small"
                                                            icon={<Info />}
                                                        >
                                                        </CTA>
                                                    </Container>
                                                </StyledDivTitleInner>
                                            </StyledDivTitle>
                                            
                                            <Container wrap={ContainerWrap.always}>

                                                {/* Runtime */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("duration")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.props.globalState.value} <Trans i18nKey="months" /></Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Milage */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("annual_milage")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.props.formatNumber(this.props.globalState.value2)} {i18next.t('milage_short')}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Downpayment */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("financing_downpayment")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["DownPayment"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Netto Sum */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("net_credit_amount_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Interest */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["InterestNominal"])} %</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>
                                                
                                                {/* Accrued Interest */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_nominal_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["Interest"])} %</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Brutto Sum */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("loan_amount")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["GrossCreditAmount"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Final Rate */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("final_rate_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["FinalRate"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Monthly Rate */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150} bold>{this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["RateCount"]} {i18next.t("monthly_rate_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static200}} stretchContent>
                                                        <div className="divider-custom-vw-blue-thick"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataAC["Response"]["Result"][1]["Calculation"]["Rate"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static200}} stretchContent>
                                                            <div className="divider-custom-vw-blue-thick"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Rate Offer Disclaimer */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}><Trans i18nKey="finance_rate_offer_disclaimer" /></Text>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                <Container stretchContent padding={{top: ContainerPadding.static450, left: ContainerPadding.static200,  right: ContainerPadding.static200}}  wrap={ContainerWrap.never} verticalAlign={ContainerVerticalAlignment.center}>
                                                    <StyledDivCol11>
                                                        <Container verticalAlign={ContainerVerticalAlignment.center} wrap={ContainerWrap.never} padding={{top: ContainerPadding.static200}}>
                                                            <StyledDivCol10>
                                                                <CTA
                                                                    tag="button"
                                                                    emphasis="primary"
                                                                    onClick={() => { this.props.setGlobalState({vergleichen: false}); this.props.setGlobalState({tabSelectedIndex: this.props.globalState.prevTabSelectedIndex}) }}
                                                                    ariaLabel={i18next.t('back')}
                                                                >
                                                                    <Trans i18nKey="back" />
                                                                </CTA>
                                                            </StyledDivCol10>
                                                        </Container>
                                                    </StyledDivCol11>
                                                    <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd} verticalAlign={ContainerVerticalAlignment.center}>
                                                        <StyledText>
                                                            <CTA
                                                                tag="a"
                                                                emphasis="tertiary"
                                                                size="small"
                                                                href={this.props.globalState.selectedModel["request_offer_link"]}
                                                                target={"_blank"}
                                                                ariaLabel={i18next.t('request_a_quote')}
                                                                onClick={() => { this.props.tracking("cta", this.props.globalState.dataLeasing, {url: `${this.props.globalState.selectedModel["request_offer_link"]}`, name: "Angebot Anfordern"}) }}
                                                            >
                                                                <Trans i18nKey="request_a_quote" />
                                                            </CTA>
                                                        </StyledText>
                                                    </Container>
                                                </Container>

                                            </Container>
                                        </div>
                                        <div>
                                             
                                        <StyledDivTitle>
                                                <StyledDivTitleInner>
                                                    <Container gutter={ContainerGutter.static200} verticalAlign={ContainerVerticalAlignment.center} wrap={ContainerWrap.never}>
                                                        <Text appearance={TextAppearance.headline0200} bold={true}><Trans i18nKey="classic_credit" /></Text>
                                                        <CTA
                                                            tag="button"
                                                            emphasis="tertiary"
                                                            onClick={() => { this.props.setGlobalState({showInfo:"classic_credit"})}}
                                                            ariaLabel="Info"
                                                            size="small"
                                                            icon={<Info />}
                                                        >
                                                        </CTA>
                                                    </Container>
                                                </StyledDivTitleInner>
                                            </StyledDivTitle>

                                            <Container wrap={ContainerWrap.always}>

                                                {/* Runtime */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("duration")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.props.globalState.value} <Trans i18nKey="months" /></Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Milage */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("annual_milage")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>-</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Downpayment */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("financing_downpayment")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["DownPayment"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Netto Sum */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("net_credit_amount_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["NetCreditAmount"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Interest */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_nominal_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["InterestNominal"])} %</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>
                                                
                                                {/* Accrued Interest */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("interest_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["Interest"])} %</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Brutto Sum */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("loan_amount")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["GrossCreditAmount"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Final Rate */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150}>{i18next.t("final_rate_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150}>-</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static400}} stretchContent>
                                                            <div className="divider-custom-vw-blue"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Monthly Rate */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>
                                                        <Text appearance={TextAppearance.copy0150} bold>{this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["RateCount"]} {i18next.t("monthly_rate_label")}</Text>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static200}} stretchContent>
                                                            <div className="divider-custom-vw-blue-thick"></div>
                                                        </Container>
                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy0150} bold>{this.formatNumber(this.props.globalState.dataCC["Response"]["Result"][1]["Calculation"]["Rate"])} {i18next.t("currency_label")}</Text>
                                                        </Container>
                                                        <Container padding={{top: ContainerPadding.static200, bottom: ContainerPadding.static200}} stretchContent>
                                                            <div className="divider-custom-vw-blue-thick"></div>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                {/* Rate Offer Disclaimer */}
                                                <Container verticalAlign={ContainerVerticalAlignment.flexEnd} wrap={ContainerWrap.never} horizontalAlign={ContainerHorizontalAlignment.spaceBetween} gutter={ContainerGutter.grid001}>
                                                    <StyledDivCol11>

                                                    </StyledDivCol11>

                                                    <StyledDivCol2>
                                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                                            <Text appearance={TextAppearance.copy150} color={TextColor.secondary}><Trans i18nKey="finance_rate_offer_disclaimer" /></Text>
                                                        </Container>
                                                    </StyledDivCol2>
                                                </Container>

                                                <Container stretchContent padding={{top: ContainerPadding.static450, left: ContainerPadding.static200,  right: ContainerPadding.static200}}  wrap={ContainerWrap.never} verticalAlign={ContainerVerticalAlignment.center}>
                                                    <StyledDivCol11>
                                                        <Container verticalAlign={ContainerVerticalAlignment.center} wrap={ContainerWrap.never}>
                                                            <StyledDivCol10>
                                                                <CTA
                                                                    tag="button"
                                                                    emphasis="primary"
                                                                    onClick={() => { this.props.setGlobalState({vergleichen: false}); this.props.setGlobalState({tabSelectedIndex: this.props.globalState.prevTabSelectedIndex}) }}
                                                                    ariaLabel={i18next.t('back')}
                                                                >
                                                                    <Trans i18nKey="back" />
                                                                </CTA>
                                                            </StyledDivCol10>
                                                        </Container>
                                                    </StyledDivCol11>
                                                    <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd} verticalAlign={ContainerVerticalAlignment.center}>
                                                        <StyledText>
                                                            <CTA
                                                                tag="a"
                                                                emphasis="tertiary"
                                                                size="small"
                                                                href={this.props.globalState.selectedModel["request_offer_link"]}
                                                                target={"_blank"}
                                                                ariaLabel={i18next.t('request_a_quote')}
                                                                onClick={() => { this.props.tracking("cta", this.props.globalState.dataLeasing, {url: `${this.props.globalState.selectedModel["request_offer_link"]}`, name: "Angebot Anfordern"}) }}
                                                            >
                                                                <Trans i18nKey="request_a_quote" />
                                                            </CTA>
                                                        </StyledText>
                                                    </Container>
                                                </Container>
                                            </Container>
                                        </div> 

                            </Flickity>

                        </StyledDivCarousel>

                    <StyledDivChevron onClick={() => { this.state.flkty.next() }}>
                        <ChevronRight />
                    </StyledDivChevron>

                </Container>
                <Container  horizontalAlign={ContainerHorizontalAlignment.center}>
                    <StyledDivCol12>
                        <CTA
                            tag="button"
                            emphasis="primary"
                            onClick={() => { this.props.setGlobalState({vergleichen: false}); this.props.setGlobalState({tabSelectedIndex: this.props.globalState.prevTabSelectedIndex}) }}
                            ariaLabel={i18next.t('back')}
                        >
                            <Trans i18nKey="back" />
                        </CTA>
                    </StyledDivCol12>
                </Container>
            
            </Container>
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Vergleichen));