import * as React from "react"
import ReactDOM from 'react-dom'
import { hot } from "react-hot-loader"
import {
    Spinner
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import styled from "styled-components"

const StyledSpinnerWrapper = styled.div`
    display: table;
    z-index: 9;
    position: fixed;
    background: rgba(255,255,255,.75);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`
const StyledSpinnerMiddle = styled.div`
    display: table-cell;
    vertical-align: middle;
`

const StyledSpinnerInner = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    text-align: center;
`

class Modal extends React.Component<any, any> {
    
    constructor(props) {
        super(props);
    }

    render() {
        return ReactDOM.createPortal(
            <StyledSpinnerWrapper>
                <StyledSpinnerMiddle>
                    <StyledSpinnerInner>
                        <Spinner variant="x-large"/>
                    </StyledSpinnerInner>
                </StyledSpinnerMiddle>
            </StyledSpinnerWrapper>,
            document.querySelector("body #reactmount [class^='StyledIntegratorRoot-']")
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Modal));