import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

//import TranslationDE_DE from "./i18n/int/de-DE.json";
//import TranslationEN_EN from "./i18n/int/en-EN.json";
let TranslationDE_DE = require("./i18n/int/de-DE.json");

const resources = {
  de: {
    translation: TranslationDE_DE
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng: 'de',
    returnObjects: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
      transSupportBasicHtmlNodes: true // allow <br/> and simple html elements in translations
    },
    resources
  });

export default i18n;
