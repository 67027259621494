import * as React from "react"
import ReactDOM from 'react-dom'
import "../i18n"
import { Trans } from 'react-i18next'
import { hot } from "react-hot-loader"
import i18next from 'i18next'
import {
    Text,
    TextAppearance,
    Breakpoints,
    Container,
    ContainerPadding,
    ContainerWrap,
    ContainerHorizontalAlignment,
    Checkbox,
    CTA
} from "@volkswagen-onehub/components-core"
import { withGlobalState } from 'react-globally'
import FocusLock from "react-focus-lock";
import styled from "styled-components"
import { CloseCircle } from "@volkswagen-onehub/icons-core"

const StyledModal = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    width: ${props => props.theme.size.grid024};
    height: 100%;
`

const StyledInnerModal = styled.div`
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    margin-left: ${props => props.theme.size.grid002};
    margin-right: ${props => props.theme.size.grid002};
    width: ${props => props.theme.size.grid020};
    height: calc(100% - ${props => props.theme.size.dynamic0250} - ${props => props.theme.size.dynamic0250});
    overflow: auto;
    margin-top: ${props => props.theme.size.dynamic0250};
    margin-bottom: ${props => props.theme.size.dynamic0250};
    @media (min-width: ${Breakpoints.b560}px) {
        width: ${props => props.theme.size.grid016};
        margin-left: ${props => props.theme.size.grid004};
        margin-right: ${props => props.theme.size.grid004};
	}
`
const StyledCloseContainer = styled.div`
    position: absolute;
    right: ${props => props.theme.size.static350};
    top: ${props => props.theme.size.static200};
    width: 24px;
    height: 24px;
`

const StyledIcon = styled.div`
    position: fixed;
    
`
let updated = false;

class Modal extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            selectedList: this.props.globalState.selectedList
        }
    }
    requestDomain = this.props.globalState.requestDomain;
    requestDomainElectric = this.props.globalState.requestDomainElectric;

    updateProps() {
        let t0 = performance.now();
        this.props.setGlobalState({showCalcSpinner: true});

        if (this.props.globalState.listBase === "electric_list") {
                                                
            this.props.updateData(this.requestDomain, "PL", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataLeasing : data });
                this.props.setGlobalState({ loadingLeasing: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })

            this.props.updateData(this.requestDomain, "PL_S", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataPL_S : data });
                this.props.setGlobalState({ loadingLeaseCareS: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })

            this.props.updateData(this.requestDomain, "PL_M", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataPL_M : data });
                this.props.setGlobalState({ loadingLeaseCareM: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })
    
            this.props.updateData(this.requestDomain, "PL_L", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataPL_L : data });
                this.props.setGlobalState({ loadingLeaseCareL: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })
        }
        else {

            this.props.updateData(this.requestDomain, this.props.globalState.kunden ? "GL" : "PL", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataLeasing : data });
                this.props.setGlobalState({ loadingLeasing: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })

            this.props.updateData(this.requestDomain, "AC", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataAC : data });
                this.props.setGlobalState({ loadingAC: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })
    
            this.props.updateData(this.requestDomain, "CC", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataCC : data });
                this.props.setGlobalState({ loadingCC: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })
        }
        this.removeSpinner(t0);
    }

    removeSpinner(t0) {
      let mintime = 500; // in ms
      let t1 = performance.now();
      let tdiff = t1-t0;
      let wait = (tdiff < mintime) ? (mintime - tdiff) : mintime;
      setTimeout(function() {
          this.props.setGlobalState({showCalcSpinner: false});
      }.bind(this), wait);
    }

    createList = () => {
        let vehicles = i18next.t(this.props.globalState.listBase);
        let selectedList = this.props.globalState.selectedList
        let children = []
        for (let i = 0; i < vehicles.length; i++) {
            
            children.push(
                <Container key={i} horizontalAlign={ContainerHorizontalAlignment.center} wrap={ContainerWrap.always}>
                    <Checkbox
                        disabled = {((selectedList.length <= 1 && selectedList.indexOf(i) != -1 ) || (selectedList.length >= 6 && selectedList.indexOf(i) == -1 )) ? true : false}
                        checked  = {selectedList.indexOf(i) == -1 ? false : true}
                        value    = {String(i)}
                        label    = {
                            <Container padding={{bottom:ContainerPadding.static250}}>
                                <Text appearance={TextAppearance.copy0200} >{vehicles[i]["name_label"]}</Text>
                            </Container>
                        }
                        onClick  = { e => {
                            /* Is the vehicle already in the selectedList? */
                            let pos = selectedList.indexOf(i);
                            if (pos == -1) {
                                /* No, to list. */
                                selectedList.push(i);
                            }
                            else {
                                /* Yes, remove from list. */
                                selectedList.splice(pos, 1);
                            }
                            /* Update Global List. */
                            this.setState({selectedList: selectedList});
                            updated = true;
                        }}
                    />
                </Container>
            )
        }
        return children
    }

    handleClose = () => {
        if (!this.props.globalState.isLive) {
            console.log("changes made? " + updated)
        }
        /* track only if updated were made */
        if (updated) {
            /*this.props.setGlobalState({ selectedList: this.state.selectedList })*/
            this.props.setGlobalState({"selectedList": this.state.selectedList})
            this.props.setGlobalState({selectedModel: i18next.t(this.props.globalState.listBase)[this.props.globalState.selectedList[0]]})
            this.updateProps()
            updated = false;
            this.props.tracking("add_vehicle", this.props.globalState.dataLeasing, "Fahrzeugliste ändern")
        }
        this.props.setGlobalState({showModal: false})
    }

    escapePress = (event) => {
        if (this.props.globalState.showModal && (event.key === "Escape" || event.code === "Escape")) {
            this.handleClose()
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', (event) => {this.escapePress(event)})
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', (event) => {this.escapePress(event)})
    }

    render() {
        
        return ReactDOM.createPortal(
            <FocusLock
                disabled={!this.props.globalState.showModal}
                returnFocus = {true} >
            { this.props.globalState.showModal && ( 
            <StyledModal>
                <StyledInnerModal>

                    <Container stretchContent padding={{left: ContainerPadding.grid002, right: ContainerPadding.grid002}} wrap={ContainerWrap.always}>

                        <Container padding={{top: ContainerPadding.dynamic0100}} horizontalAlign={ContainerHorizontalAlignment.center}>
                            <Text appearance={TextAppearance.headline0300} bold={true}><Trans i18nKey="car_selection" /></Text>
                        </Container>

                        <Container padding={{top: ContainerPadding.static200}} horizontalAlign={ContainerHorizontalAlignment.center}>
                            <Text appearance={TextAppearance.copy0200}><Trans i18nKey="car_selection_copy" /></Text>
                        </Container>

                        <Container  padding={{top: ContainerPadding.dynamic0130}} wrap={ContainerWrap.always} stretchContent> 
                            {this.createList()}
                        </Container>

                        <Container padding={{top: ContainerPadding.dynamic0250, bottom:ContainerPadding.dynamic0250}}  horizontalAlign={ContainerHorizontalAlignment.center} >
                            <CTA
                                tag="button"
                                emphasis="primary"
                                onClick={() => this.handleClose()}
                                ariaLabel={i18next.t('update_vehicles')}
                            >
                                <Trans i18nKey="update_vehicles" />
                            </CTA>
                        </Container>
                    </Container>
                    
                    <StyledCloseContainer>
                        <StyledIcon className="close-modal">
                            <CTA
                                tag="button"
                                emphasis="tertiary"
                                onClick={() => this.handleClose()}
                                ariaLabel="Fahrzeugauswahl schließen"
                                icon={<CloseCircle />}
                            />
                        </StyledIcon>
                    </StyledCloseContainer>
                    
                </StyledInnerModal>
            </StyledModal>
            )}
            </FocusLock>,
            document.querySelector("body #reactmount [class^='StyledIntegratorRoot-']")
        );
    }
}
declare let module: object;

export default withGlobalState(hot(module)(Modal));