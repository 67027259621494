import * as React from "react"
import { hot } from "react-hot-loader"
import { withGlobalState } from 'react-globally'

let timeout = null

class UpdateProps extends React.Component<any, any> {
    constructor(props) {
        super(props);     
    }

    updateProps() {
        let t0 = performance.now();
        this.props.setGlobalState({showCalcSpinner: true});

        if (this.props.globalState.listBase === "electric_list") {
                                                
            this.props.updateData(this.props.globalState.requestDomainElectric, "PL", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataLeasing : data });
                this.props.setGlobalState({ loadingLeasing: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })

            this.props.updateData(this.props.globalState.requestDomainElectric, "PL_S", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataPL_S : data });
                this.props.setGlobalState({ loadingLeaseCareS: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })

            this.props.updateData(this.props.globalState.requestDomainElectric, "PL_M", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataPL_M : data });
                this.props.setGlobalState({ loadingLeaseCareM: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })
    
            this.props.updateData(this.props.globalState.requestDomainElectric, "PL_L", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataPL_L : data });
                this.props.setGlobalState({ loadingLeaseCareL: false })
            }).catch(err => {
                console.error("Error", err);
                this.props.setGlobalState({ calcErrored: true })
            })
        }
        else {

            this.props.updateData(this.props.globalState.requestDomain, this.props.globalState.kunden ? "GL" : "PL", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataLeasing : data });
                this.props.setGlobalState({ loadingLeasing: false })
            }).catch(err => {
                this.props.setGlobalState({ calcErrored: true })
            })
    
            this.props.updateData(this.props.globalState.requestDomain, "AC", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataAC : data });
                this.props.setGlobalState({ loadingAC: false })
            }).catch(err => {
                this.props.setGlobalState({ calcErrored: true })
            })
    
            this.props.updateData(this.props.globalState.requestDomain, "CC", this.props.globalState.value2, this.props.globalState.value, this.props.globalState.leasingSonderZahlung, this.props.globalState.selectedModel.key, this.props.globalState.selectedModel.year, this.props.globalState.selectedModel.price, this.props.globalState.selectedModel.price_total).then(data => {
                this.props.setGlobalState({ dataCC : data });
                this.props.setGlobalState({ loadingCC: false })
            }).catch(err => {
                this.props.setGlobalState({ calcErrored: true })
            })
        }
        this.removeSpinner(t0);
    }

    componentDidMount() {
        if (!this.props.globalState.isLive) {
            console.log("start renderless component")
            console.log("Request Domain: " + this.props.globalState.requestDomain)
        }
    }

    componentDidUpdate(prevProps) {
        /* One globalState value to detect what was changed? */
        if (this.props.globalState.value !== prevProps.globalState.value ||
            this.props.globalState.value2 !== prevProps.globalState.value2 ||
            this.props.globalState.leasingSonderZahlung !== prevProps.globalState.leasingSonderZahlung) {
          
          clearTimeout(timeout);
  
          timeout = setTimeout(()=>{
  
            if (!this.props.globalState.isLive) {
                //console.log("change detected:")
            }
            
            this.updateProps()
            this.props.tracking("calculate", this.props.globalState.lastChanged[2], this.props.globalState.lastChanged[1])
  
          }, (500)
          )}
      }

    removeSpinner(t0) {
        let mintime = 500; // in ms
        let t1 = performance.now();
        let tdiff = t1-t0;
        let wait = (tdiff < mintime) ? (mintime - tdiff) : mintime;
        setTimeout(function() {
            this.props.setGlobalState({showCalcSpinner: false});
        }.bind(this), wait);
      }

    render() {
        return null;
    }
}
declare let module: object;

export default withGlobalState(hot(module)(UpdateProps));
